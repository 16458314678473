import * as React from "react";
import { Layout, Container } from "components/layout";
import { SEO } from "components/common/SEO";
import { ViewHeader } from "components/view/ViewHeader";
import { NotFound } from "components/view/NotFound";
import { MapBackground } from "components/view/LandingMap/MapBackground";
import { MapboxMap } from "components/common/MapboxMap";

const NotFoundPage = () => (
  <>
    <Layout fullscreen dark>
      <SEO title="404" />
      <ViewHeader dark />
      <MapboxMap interactive={false} id="landing-map">
        <MapBackground />
      </MapboxMap>
      <Container hasSidebar centerColumn hasTop>
        <NotFound />
      </Container>
    </Layout>
  </>
);

export default NotFoundPage;
